import Link from '@mui/material/Link'
import { useLocation, useNavigate } from 'react-router-dom'
import { isPathActive } from '@/utils'

const NavigationLink = ({ path, label, onClick }) => {
    const navigate = useNavigate()
    const isActive = isPathActive(path, useLocation())
    return <Link href={path} 
        className={isActive ? "active" : ""}
        onClick={e => {
            if ( onClick ) onClick()
            navigate(path)
            e.preventDefault()
        }}>
            {label}
    </Link>
}

export default NavigationLink