import LoadingView from '@views/Loading'

const Loading = () => {
    return (
        <div className="main-layout">
            <main>
                <LoadingView />
            </main>
        </div>
    )
}

export default Loading