import PersonOutlinedIcon from '@mui/icons-material/Person2Outlined'
import IconButton from '@mui/material/IconButton'
import { useGetProfileQuery } from "@redux/profile-api"

const AccountMenu = ({ onClick, displayName=false }) => {
    const { data: profile } = useGetProfileQuery()
    const name = profile?.name || profile?.preferred_username
    const iconButton = <IconButton size="large" 
        aria-label="account" 
        onClick={displayName ? undefined : onClick} 
        style={displayName ? { pointerEvents: "none" } : undefined}
    >
        <PersonOutlinedIcon />
    </IconButton>
    if ( !displayName ) return iconButton
    return <IconButton style={{ 
        backgroundColor: "rgba(213, 242, 254, 0.54)", 
        padding: 0, 
        borderRadius: 32,
        minWidth: 0,
        flexShrink: 1,
        justifyContent: "flex-start"
    }} onClick={onClick}>
        { iconButton }
        <div style={{
            minWidth: 0,
            marginLeft: 12, 
            marginRight: 24, 
            color: "black",
            fontSize: 14,
            fontWeight: 500,
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "block",
            whiteSpace: "nowrap"    
        }}>{ name }</div>
    </IconButton>
}

export default AccountMenu