import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BACKEND_BASE_URL } from "@/const"
import { contextSelector } from "@redux/context-slice"

const baseQuery = fetchBaseQuery({
		baseUrl: BACKEND_BASE_URL,
	//	credentials: 'omit'
		redirect: 'manual',
		prepareHeaders: (headers, { getState }) => {
			const { company, parent } = contextSelector(getState())
			if ( company ) headers.set("X-Context-Company", company)
			if ( parent ) headers.set("X-Context-Parent", parent)
			return headers
		},	
		fetchFn: async (url, options) => {
			return await fetch(url, options);
		}
	})

export const api = createApi({
	reducerPath: "api",
	tagTypes: [
		"Profile",
		"Notification",
		"Company",
		"Order",
		"Parcel",
		"Person",
		"Customer",
		"Product",
		"AdditionalService",
		"ParcelType",
		"ServicePoint",
		"ÅlandicPostalCode"
	],
	baseQuery: baseQuery,
	endpoints: () => ({}),
})
