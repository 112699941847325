import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    open: false,
    title: '',
    content: '',
    onConfirm: () => {},
    doneMessage: null
 }

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    openConfirmation(state, action) {
      state.open = true
      state.title = action.payload.title
      state.content = action.payload.content
      state.onConfirm = action.payload.onConfirm
      state.doneMessage = action.payload.doneMessage
    },
    closeConfirmation(state) {
        state.open = false
    }
  }
})

export const { openConfirmation, closeConfirmation } = confirmationSlice.actions
export const confirmationSelector = state => state.confirmation