import CircularProgress from '@mui/material/CircularProgress'
import withHelmet from '@components/withHelmet'

const Loading = () => {
    return (
        <div className="loading-view">
            <CircularProgress size="85px" />
        </div>
    )
}

export default withHelmet(Loading)