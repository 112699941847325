import { Outlet } from "react-router-dom"
import Header from "@components/Header"
import Footer from "@components/Footer"
import { useGetProfileQuery } from "@redux/profile-api"
import { useSelector, useDispatch } from "react-redux"
import { snackbarSelector, closeSnackbar } from "@redux/snackbar-slice"
import LoadingLayout from '@layouts/Loading'
import Snackbar from '@mui/material/Snackbar'
import ConfirmationDialog from "@components/ConfirmationDialog"

const Main = ({children}) => {
    const dispatch = useDispatch()
    const snackbar = useSelector(snackbarSelector)
    const { data: profile, isLoading } = useGetProfileQuery()
    if ( !isLoading && !profile ) {
        setTimeout(() => {
            window.location = '/login'
        }, 200)
        return <LoadingLayout />
    } else if ( isLoading ) {
        return <LoadingLayout />
    }
    return (
        <div className="main-layout">
            <Header />
            <main>{ children || <Outlet /> }</main>
            <Footer />
            <ConfirmationDialog />
            <Snackbar
                key={snackbar.key}
                open={snackbar.open}
                autoHideDuration={snackbar.duration}
                onClose={() => dispatch(closeSnackbar())}
                message={snackbar.message}
            />
        </div>
    )
}

export default Main