import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { MENU_LINKS } from '@/const'
import { isPathActive, getPageTitle } from '@/utils'

const withHelmet = (Component, label=null) => {
    return props => {
        const location = useLocation()
        return <>
            <Helmet>
                <title>
                    {getPageTitle(label || MENU_LINKS.find(({ path }) => isPathActive(path, location))?.label)}
                </title>
            </Helmet>
            <Component {...props} />
        </>
    }
}

export default withHelmet