import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    open: false,
    duration: 5000,
    type: 'info',
    message: '',
    key: 0
 }

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, action) {
        state.open = true
        state.message = action.payload
        state.key ++
    },
    closeSnackbar(state) {
        state.open = false
        return state
    }
  }
})

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions
export const snackbarSelector = state => state.snackbar