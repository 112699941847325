import { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector, useDispatch } from "react-redux"
import { closeConfirmation } from "@redux/confirmation-slice"
import { openSnackbar } from '@redux/snackbar-slice'
import { CircularProgress } from '@mui/material';

const ConfirmationDialog = () => {
    const confirmation = useSelector(state => state.confirmation)
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)
    const onClose = () => {
        if ( !inProgress ) {
            dispatch(closeConfirmation())
        }
    }
    return <Dialog open={confirmation.open} onClose={onClose} fullWidth>
        <DialogTitle>{confirmation.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{confirmation.content}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginBottom: 4 }}>
            <Button disabled={inProgress} onClick={async () => {
                setInProgress(true)
                try {
                    await confirmation.onConfirm()
                    if ( confirmation.doneMessage?.length ) {
                        dispatch(openSnackbar(confirmation.doneMessage))
                    }
                } catch ( e ) {
                    console.error(e)
                }
                setInProgress(false)
                onClose()
            }} autoFocus variant='contained' style={{ paddingLeft: 10 }}>
                { inProgress 
                    ? <CircularProgress />
                    : <CheckIcon style={{ marginRight: 6, width: 18, height: 18, marginBottom: 2 }} />
                } Kyllä
            </Button>
            <Button disabled={inProgress} onClick={onClose}>Peruuta</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmationDialog