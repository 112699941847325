import React from 'react'
import { useDispatch } from "react-redux"
import Pakettipiste from '@images/pakettipiste-logo.png'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import { useGetProfileQuery } from "@redux/profile-api"
import { setDrawerOpen } from '../redux/ui-slice'
import NavigationLink from '@components/NavigationLink'
import { MENU_LINKS, USE_DRAWER_MEDIA_QUERY } from '@/const'
import useMediaQuery from '@mui/material/useMediaQuery'
import AccountMenu from '@components/AccountMenu'
import CompanyDropdown from './CompanyDropdown'
import { logout } from '../utils'

const Header = () => {
    const dispatch = useDispatch()
    const useDrawer = useMediaQuery(USE_DRAWER_MEDIA_QUERY)

    const { data: profile } = useGetProfileQuery()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = e => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null)

    const hasRole = role => profile?.roles?.includes('admin') 
        || profile?.roles?.includes(role)

    const withCompanyDropdown = hasRole('client-admin')
    const displayNameOnAccountMenu = !withCompanyDropdown /*|| useDrawer*/

    const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    return (
        <header className="header">
            <div>
                {
                    useDrawer &&
                    <div className="menu-icon" style={{ marginRight: 24 }}>
                        <IconButton size="large" variant="" aria-label="menu" onClick={() => dispatch(setDrawerOpen(true))}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                }
                { !sm &&
                    <div className="logo">
                        <img src={Pakettipiste} alt="Pakettipiste" width="160" />
                    </div>
                }
                {
                    !useDrawer && 
                        <div className="menu">
                                <div>
                                    {
                                        MENU_LINKS.map(link =>
                                            link.role != null && !hasRole(link.role) ? null :
                                            <NavigationLink path={link.path} label={link.label} key={link.path} />
                                        )
                                    }
                                </div>
                            </div>
                }
                <div className="account" style={
                    useDrawer 
                        ? { flex: 1, justifyContent: "end", flexWrap: "nowrap", overflowY: "overlay" } 
                        : undefined 
                }>
                    {withCompanyDropdown && <CompanyDropdown  /> }
                    <AccountMenu onClick={handleClick} displayName={displayNameOnAccountMenu} />
                    <Menu anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        style={{ marginTop: 7 }}
                    >
                        {
                            !displayNameOnAccountMenu 
                                && profile 
                                && <div style={{ 
                                    padding: '6px 16px 6px 16px', 
                                    fontSize: 14, 
                                    fontWeight: 500,
                                    backgroundColor: "rgba(213, 242, 254, 0.54)",
                                    margin: 6,
                                    marginTop: 0
                                }}>
                                    { profile.name || profile.preferred_username }
                                </div> 
                            }
                        <MenuItem style={{ fontWeight: 500 }} onClick={logout}>
                            <LogoutIcon /><div style={{ marginRight: 10 }}></div>Kirjaudu ulos
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </header>
    )
}

export default Header