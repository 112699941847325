import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    company: null,
    parent: null
 }

export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setCompanyContext(state, action) {
        state.company = action.payload
        state.parent = null
    },
    setParentContext(state, action) {
        state.parent = action.payload
        state.company = null
    },
  }
})

export const { setCompanyContext, setParentContext } = contextSlice.actions
export const contextSelector = state => state.context