import { fiFI } from '@mui/x-data-grid'

export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || "https://localhost/api/v1"
export const OPENSEARCH_DASHBOARDS_URL = process.env.REACT_APP_OPENSEARCH_DASHBOARDS_URL || "https://localhost/dashboards"
export const OPENSEARCH_DASHBOARDS_REPORT_VIEW_ID = process.env.REACT_APP_OPENSEARCH_DASHBOARDS_REPORT_VIEW_ID || '55a07490-350d-11ee-9b6d-dbfe5e3ae994'
export const OPENSEARCH_DASHBOARDS_LANDING_VIEW_ID = process.env.REACT_APP_OPENSEARCH_DASHBOARDS_LANDING_VIEW_ID || '3b4ff700-31fc-11ee-9b6d-dbfe5e3ae994'
export const ROWS_PER_PAGE = process.env.REACT_APP_ROWS_PER_PAGE || 25
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || ""

export const OPENSEARCH_DASHBOARDS_CSS = `
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300,400,500,600,700,800;
        src: url(/fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
    }
    body {
        background-color: white;
        font-family: Montserrat;
    }
    #dashboardViewport {
        overflow: auto;
    }
    .embPanel__header {
        background-color: #1D3488;
        color: white;
    }
    .embPanel__titleText {
        color: white;
        font-weight: 500;
        font-size: 16px;
        margin: 3px;
    }
    .embPanel__content {
        background-color: #D5F2FE;
        color: #1D3488;
    }
    .embPanel {
        border: 0 !important;
        box-shadow: none !important;
    }
    .embPanel__title {
        justify-content: center;
    }
    .euiPopover, .visLegend__toggle {
        display: none !important;
    }
    .react-grid-item {
        padding: 0px 12px 18px 12px;
    }
    .mtrVis__container {
        display: flex;
    }
    .mtrVis__container span {
        margin-right: 8px;
    }
    .mtrVis__container * {
        font-size: 32px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
`

export const MENU_LINKS = [
    {path: '/', label: 'Etusivu'},
    {path: '/history', label: 'Lähetykset'},
    {path: '/order', label: 'Tilaus', role: 'order-writer'},
    {path: '/reports', label: 'Raportit', role: 'report-reader'},
    {path: '/notifications', label: 'Tiedotteet', role: 'notification-writer'},
    {path: '/users', label: 'Käyttäjät', role: 'client-admin'}
]

export const USE_DRAWER_MEDIA_QUERY = '(max-width:1275px)'

export const VAK_ADDITIONAL_SERVICE_ID = 2
export const ERILLISNOUTO_ADDITIONAL_SERVICE_ID = 7

export const DataGridLocale = {
    ...fiFI.components.MuiDataGrid.defaultProps.localeText,
    columnMenuHideColumn: "Piilota sarake",
    columnMenuManageColumns: "Hallitse sarakkeita",
    checkboxSelectionHeaderName: "Valinta"
}