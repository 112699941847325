import { api } from "./api"
import { tagProvider } from "@/utils"

export const companyApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCompanies: builder.query({
			query: () => 'companies?inactive=false',
			providesTags: tagProvider('Company')
		}),
	})
})

export const { 
	useGetCompaniesQuery
} = companyApi
