import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import "@scss/styles.scss"
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'
import MainLayout from '@layouts/Main'
import LoadingView from '@views/Loading'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import fi from 'date-fns/locale/fi'
import MenuDrawer from '@components/MenuDrawer'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat'],
    allVariants: {
      fontFamily: 'Montserrat',
    },
  },
  palette: {
    primary: {main: '#1D3488', contrastText: '#FFFFFF'},
    paketti_tumma_sininen: {main: '#1D3488', contrastText: '#FFFFFF'},
    paketti_kirkas_sininen_1: {main: '#009ADA', contrastText: '#FFFFFF'},
    paketti_kirkas_sininen_2: {main: '#52B9E9', contrastText: '#FFFFFF'},
    paketti_punainen: {main: '#D12831', contrastText: '#FFFFFF'},
    paketti_vaalea_sininen: {main: '#D5F2FE', contrastText: '#000000'},
    paketti_beige: {main: '#F6F2F3', contrastText: '#1D3488'},
    paketti_vaalea_punainen: {main: '#FAE6E8', contrastText: '#000000'},
    paketti_kirkas_vaaleansininen: {main: '#66CAFA', contrastText: '#000000'},
    paketti_valkoinen: {main: '#FFFFFF', contrastText: '#000000'},
    paketti_musta: {main: '#000000', contrastText: '#FFFFFF'}
  },
})

const MainView = lazy(() => import("./views/Main"))
const UsersView = lazy(() => import("./views/Users"))
const OrderView = lazy(() => import("./views/Order"))
const ReportsView = lazy(() => import("./views/Reports"))
const HistoryView = lazy(() => import("./views/History"))
const NotificationsView = lazy(() => import("./views/Notifications"))
const EditOrderView = lazy(() => import("./views/EditOrder"))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
          <Router>
            <Suspense fallback={<MainLayout><LoadingView /></MainLayout>}>
              <Routes>
                <Route element={<MainLayout />}>
                  <Route path="/" element={<MainView />} />
                  <Route path="/users" element={<UsersView />} />
                  <Route path="/order" element={<OrderView />} />
                  <Route path="/order/edit" element={<EditOrderView />} />
                  <Route path="/order/edit/:id" element={<EditOrderView />} />
                  <Route path="/reports" element={<ReportsView />} />
                  <Route path="/history" element={<HistoryView />} />
                  <Route path="/notifications" element={<NotificationsView />} />
                  <Route path="*" element={<Navigate replace to="/" />} />
                </Route>
              </Routes>
            </Suspense>
            <MenuDrawer />
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
