const Footer = () => {

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="column">
                    <div className="header">Asiakaspalvelu</div>
                    <div className="content" style={{ minWidth: 330 }}>
                        <a href="tel:+358207642300">020 764 2300</a>
                        <a href="mailto:suurasiakaspalvelu@pakettipiste.fi">suurasiakaspalvelu@pakettipiste.fi</a>
                    </div>
                </div>
                <div className="column">
                    <div className="header">Hyvä tietää</div>
                    <div className="content" style={{ display: 'flex', flexFlow: 'row' }}>
                        <div style={{ display: 'flex', flexFlow: 'column', minWidth: 140 }}>
                            <a href="https://www.pakettipiste.fi/ohjeet-ja-ehdot/">Ohjeet ja ehdot</a>
                            <a href="https://www.pakettipiste.fi/usein-kysyttya/">Usein kysyttyä</a>
                            <a href="https://www.pakettipiste.fi/polttoainelisa/">Polttoainelisä</a>
                        </div>
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                            <a href="https://www.pakettipiste.fi/tietosuojaseloste/">Tietosuojaseloste</a>
                            <a href="https://www.pakettipiste.fi/vahinkotapaukset/">Vahinkotapaukset</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer