import { api } from "./api"

export const profileApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getProfile: builder.query({
			query: () => 'profile'/*,
			providesTags: ({ preferred_username }) => 
				[{ type: "Profile", preferred_username }]*/
		}),
	})
})

export const { 
	useGetProfileQuery
} = profileApi
