import { combineReducers } from "redux"
import { configureStore } from '@reduxjs/toolkit'
import { api } from "@redux/api"
import { snackbarSlice } from "@redux/snackbar-slice"
import { confirmationSlice } from "@redux/confirmation-slice"
import { contextSlice } from "@redux/context-slice"
import { uiSlice } from "@redux/ui-slice"

const reducers = combineReducers({
	[api.reducerPath]: api.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [confirmationSlice.name]: confirmationSlice.reducer,
  [contextSlice.name]: contextSlice.reducer,
  [uiSlice.name]: uiSlice.reducer
})

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
        serializableCheck: false
    }).concat(api.middleware)
})